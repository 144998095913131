
import React from 'react';
import { Page, Document, Image, StyleSheet } from '@react-pdf/renderer';
import CertPieFirma from './CertPieFirma.js'
import CertTitulo from './CertTitulo.js'
import Estudiante from './Estudiante.js';


const styles = StyleSheet.create({
    pageBackground: {
        position: 'absolute',
        minWidth: '100%',
        minHeight: '100%',
        display: 'none',
        height: '100%',
        width: '100%',
      },
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
        width:'full',
    }, 
    logo: {
        width: 300,
        height: 250,
        marginLeft: 'auto',
        marginRight: 'auto'
    }
  });
  
  const Documento = ({certDatos}) => (
            <Document>
                <Page size="LETTER" style={styles.page}>
                    <CertTitulo/>
                    <Estudiante certDatos={certDatos} />
                    <CertPieFirma />
                </Page>
            </Document>
        );
  
  export default Documento

