import { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
export default function ListUser() {
    const { id } = useParams();
    const navigate = useNavigate();
    var valores = [];
    const [inputs, setInputs] = useState([]);

    useEffect(() => {
        if (inputs.length === 0) {
            getUser();
        }
    }, [valores]);


    function getUser() {
        axios.get(`https://api.universitea.cl/users/${id}`)
            .then(function (response) {
                setInputs(response.data);
            })
    };
    console.log(inputs);
    const loadForm = () => {
        let campos = document.querySelectorAll("input");
        campos.forEach((element, index, arr) => {
            element.value = inputs[0][element.name];
            valores = ({ ...valores, [element.name]: element.value });
        });

    }

    const handleChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        valores = ({ ...valores, [name]: value });
    }

    const handleSubmit = (event) => {
        event.preventDefault();
        axios.put(`https://api.universitea.cl/users/${id}/edit`, valores)
            .then(function (response) {
                console.log(response);
                navigate('/AdminUser');
            });
    }
    //
    return (
        <div>
            <h1>Edit user</h1>
            <form onSubmit={handleSubmit} id={"formulario"} onLoad={loadForm()}>
                <input value={inputs.id} type="number" name="id" onChange={handleChange} required={'yes'} hidden={true} />
                <table cellSpacing="10">
                    <tbody>
                        <tr>
                            <th>
                                <label> Name: </label>
                            </th>
                            <td>
                                <input value={inputs.name} type="text" name="name" onChange={handleChange} required={'yes'} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Email: </label>
                            </th>
                            <td>
                                <input value={inputs.email} type="text" name="email" onChange={handleChange} required={'yes'} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>Mobile: </label>
                            </th>
                            <td>
                                <input value={inputs.mobile} type="text" name="mobile" onChange={handleChange} required={'yes'} />
                            </td>
                        </tr>
                        <tr>
                            <th>
                                <label>UniPass: </label>
                            </th>
                            <td>
                                <input value={inputs.password} type="text" name="password" onChange={handleChange} required={'yes'} />
                            </td>
                        </tr>
                        <tr>
                            <td colSpan="2" align="right">
                                <button>Save</button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
    )

}
