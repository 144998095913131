import { Disclosure } from '@headlessui/react';
import React, { useState } from 'react';
import { Bars3Icon } from '@heroicons/react/24/outline';
import Drawer from "./Drawer.tsx";
import Drawerdata from "./Drawerdata.tsx";
import Signdialog from "./Signdialog.tsx";
import Registerdialog from "./Registerdialog.tsx";
import Logo from '../Certificado/Universitea.png'

interface NavigationItem {
    name: string;
    href: string;
    current: boolean;
}

const navigation: NavigationItem[] = [

    { name: 'Inicio', href: '/', current: true },
    { name: 'Actividades y Certificaciones', href: '#actividades', current: false },
    { name: 'Docentes', href: '#docentes', current: false },
    // { name: 'Videollamada',href: 'conferencia', current: false },
    // { name: 'Usuarios', href: 'AdminUser', current: false },
    // { name: 'Diplomas', href: 'certificado', current: false },

];

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ');
}

const CustomLink = ({ href, onClick, children }: { href: string; onClick: () => void; children: React.ReactNode }) => {
    return (
        <a href={href} >
            <span
                onClick={onClick}
                className="px-3 py-4 text-lg font-normal "
            >
                {children}
            </span>
        </a>
    );
};


const Navbar = () => {
    const [isOpen, setIsOpen] = React.useState(false);

    const [currentLink, setCurrentLink] = useState('/');

    const handleLinkClick = (href: string) => {
        setCurrentLink(href);
    };
    return (
        <Disclosure as="nav" className="navbar">
            <div className="
                        bg-white
                        static 
                        flex
                        h-20 md:h-20 
                        items-center 
                        justify-between 
                        mx-auto 
                        px-1 py-4 pb-4 lg:px-2
                        ">
                <div className="flex flex-1 items-center sm:items-stretch sm:justify-start">
                    {/* LOGO */}
                    <div className="flex flex-shrink-0 items-center">
                        <img
                            className="block h-20 w-78"
                            // src={'/assets/logo/logo.svg'}
                            src={Logo}
                            alt="Universitea-Caminantes"
                        />
                    </div>
                    {/* LINKS */}
                    <div className="hidden lg:block m-auto">
                        <div className="flex space-x-1">
                            {navigation.map((item) => (
                                <CustomLink
                                    key={item.name}
                                    href={item.href}
                                    onClick={() => handleLinkClick(item.href)}>
                                    <span
                                        className={classNames(
                                            item.href === currentLink ? 'underline-links' : 'text-slategray',
                                            'px-3 py-4 text-lg font-normal opacity-75 hover:opacity-100'
                                        )}
                                        aria-current={item.href ? 'page' : undefined}>
                                        {item.name}
                                    </span>
                                </CustomLink>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='px-2 lg:block hidden'>
                    {/* SIGNIN DIALOG */}
                    {/* <Signdialog /> */}
                </div>
                <div className='px-2 lg:block hidden'>
                    {/* REGISTER DIALOG */}
                    {/* <Registerdialog /> */}
                </div>
                {/* DRAWER FOR MOBILE VIEW */}{/* DRAWER ICON */}
                <div className='lg:hidden '>
                    <Bars3Icon className="block h-5 w-5" aria-hidden="true" onClick={() => setIsOpen(true)} />
                </div>
                {/* DRAWER LINKS DATA */}
                <Drawer isOpen={isOpen} 
                    setIsOpen={setIsOpen}>
                    <Drawerdata />
                </Drawer>
            </div>
        </Disclosure>
    );
};

export default Navbar;
