import React from 'react';
import {View, StyleSheet, Image } from '@react-pdf/renderer';
import topright from './topright.png'

const styles = StyleSheet.create({

    titleContainer: {
        marginTop: 4,
    },
    reportTitle: {
        color: '#000000',
        letterSpacing: 4,
        fontSize: 25,
        textAlign: 'center',
        textTransform: 'uppercase',
    }
});


const CertTitulo = () => (
    <View >
        <Image src={topright} />
    </View>
);

export default CertTitulo