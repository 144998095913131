import React from 'react';
const Banner = () => {
    return (
        <div id="home-section" className='bg-lightkblue pt-2 lg:pt-3'>
            <div>
                <img src="./assets/banner/mahila.png" alt="Universitea-Caminantes" className='mx-auto w-90'/>
            </div>
            <div className=' mx-auto'>
                    <h1 className='text-midnightblue p-4 text-2xl 
                            sm:text-4xl font-semibold text-center 
                            pt-4 lg:pt-0'>
                        Certificación de Competencias en Autismo
                    </h1>
                    <h3 className='text-charcoal text-normal font-normal text-center py-1 opacity-75 '>
                        Para profesionales de la Educación, Terapeutas y Familias.</h3>
            </div>

        </div>
    )
}
export default Banner;
