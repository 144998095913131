import React from 'react';
import {Text, View, StyleSheet } from '@react-pdf/renderer';

const styles = StyleSheet.create({
    headerContainer: {
        marginTop: 6,
        alignItems:'center',
    },
    parrafo: {
        paddingBottom: 4,
        fontSize:16
    },
    grande:{
        marginTop:6,
        marginBottom:6,
        fontSize: 24,
        textAlign:'center'
    },
    nombre:{
        marginTop:6,
        marginBottom:6,
        fontSize: 28,
        textAlign:'center'
    },

    actividades:{
        marginTop:6,
        marginBottom:6,
        fontSize: 14,
        textAlign:'center'
    }

  });


  const Estudiante = ({certDatos}) => (
    <View style={styles.headerContainer}>
        <Text style={styles.nombre}>{certDatos.certNombre}</Text>
        <Text style={styles.parrafo}>{certDatos.certProfesion} {"Rut: "}{certDatos.certRut}</Text>
        <Text style={styles.parrafo}>{"Realizó el programa Universitea-Caminantes: "}</Text>
        <Text style={styles.grande}>{certDatos.certPrograma}</Text>
        <Text style={styles.grande}>{"Extensión : "}{certDatos.certHoras} {" horas"}</Text>
        <Text style={styles.grande}>{certDatos.certPeriodo} </Text>
        <Text style={styles.grande}>{"Se desempeñó en forma "}{certDatos.certDesemp} </Text>
        <Text style={styles.actividades}>{"Funciones realizadas:"}{certDatos.certActiv1}</Text>
        <Text style={styles.actividades}>{certDatos.certActiv2}</Text>
    </View>
  );
  
  export default Estudiante