import React from 'react';
import { Page, Text, View, Document, StyleSheet, Image } from '@react-pdf/renderer';
import ReactPDF from '@react-pdf/renderer';
import { PDFDownloadLink, PDFViewer } from '@react-pdf/renderer';
import Documento from './Documento.tsx';
// Create Document Component

const certDatos = 
  // {certNum:43,
  // certNombre:"Jennifer Llanquinao, ",
  // certRut:"19.999.999-9",
  // certProfesion:" Terapeuta Ocupacional, ",
  // certPrograma: "Terapia Ocupacional Grupal en Autismo",
  // certHoras: "12"},
  {certNum:44,
    certNombre:"Francisca Nicolette Gómez Ahumada, ",
    certRut:"20.753.232-0",
    certProfesion:"Estudiante de Terapia Ocupacional, ",
    certPrograma: "Pasantía en Terapia Ocupacional Grupal en Autismo",
    certPeriodo: " Diciembre 2023 y Enero 2024",
    certHoras: "46",
    certDesemp: "Destacada",
    certActiv1: "Talleres, Planificación, Materiales, ",
    certActiv2: "Retroalimentación a los padres"
  }
    
  

function Certificado() {
  return (
    <div >
          <PDFDownloadLink document={<Documento certDatos={certDatos}/>} fileName={certDatos.certNum+'.pdf'}>
        {({ loading, url, error, blob }) =>
              loading ? (
                <button>Abriendo documento...</button>
              ) : (
                <button>Descargar</button>
              )
            }
        </PDFDownloadLink>
      <PDFViewer width={600} height={700}>
        <Documento certDatos={certDatos} />
      </PDFViewer>
    </div>
  )
};
export default Certificado;