import { Dialog, Transition } from '@headlessui/react'
import React, { Fragment, useState } from 'react'
import { LockClosedIcon } from '@heroicons/react/20/solid'
import axios from 'axios';



interface Data {
    name: string;
    email: string;
    mobile: number;
    actividad: number;
    cuidador: boolean;
    profesional: boolean;
    password:string;

}

const data: Data[] = [];

const Inscribe = ({ parametros, nomActividad }) => {
    let [isOpen, setIsOpen] = useState(false)
    const [inputs, setInputs] = useState(data);

    const closeModal = () => {
        setInputs([]);
        setIsOpen(false)
    }
    const openModal = () => {
        setIsOpen(true);
        setInputs(values => ({ ...values,'password':'1234', 'actividad':parametros.codigo.toString() , 'cuidador': '-1', 'profesional': '-1' }));
    }
    const handleChanges = (event: { target: { name: any; value: any; }; }) => {
        setInputs(values => ({ ...values, [event.target.name]: event.target.value }));
        valida()
    }

    const valida = () => {
        var elem = document.getElementsByTagName('input');
        var errors = 0;
        for (let i = 0; i < elem.length; i++) {
            if (elem[i].value === "" || elem[i].value === " ") {
                ++errors;
                elem[i].className = " bg-orange-100 values relative block w-full appearance-none rounded-none rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
            };
        }
        if (errors !== 0) {
            return false;
        } else
            return true;
    }
    const handleSubmit = (event) => {
        event.preventDefault();
        if (valida()) {
            const inscribe_participante = axios.post('https://api.universitea.cl', inputs).then(function (response) {
                if (response.data.status === 1) { alert("inscripción ok ") }
                else {
                    
                    alert("No te inscribiste, puede que no hayas completado todos los datos o que no hayas hecho click en el botón 'Guardar'");
                };
                setInputs([]);
                closeModal();
            });
        }
    }
    
    if (parametros.vigente) {
        return (
            <>
                <div role="button" className="text-white font-medium grid justify-items-center 
                    align-middle bg-violet-400 rounded-full py-4   w-36 hover:scale-110"
                    onClick={openModal}>
                    Inscripción
                </div>
                <Transition appear show={isOpen} as={Fragment}>
                    <Dialog as="div" className="relative z-10" onClose={closeModal}>
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-black bg-opacity-25" />
                        </Transition.Child>
                        <div className="fixed inset-0 overflow-y-auto">
                            <div className="flex min-h-full items-center justify-center p-4 text-center">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-out duration-300"
                                    enterFrom="opacity-0 scale-95"
                                    enterTo="opacity-100 scale-100"
                                    leave="ease-in duration-200"
                                    leaveFrom="opacity-100 scale-100"
                                    leaveTo="opacity-0 scale-95"
                                >
                                    <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all">
                                        <div className="flex min-h-full items-center justify-center py-12 px-4 sm:px-6 lg:px-8">
                                            <div className="w-full max-w-md space-y-8">
                                                <div>
                                                    <img
                                                        className="mx-auto h-24 w-auto"
                                                        src="/assets/logo/logo.svg"
                                                        alt="Universitea" />
                                                    <h4 className='text-center font-bold'>Inscripción</h4>
                                                    <h2 className="mt-6 text-center  tracking-tight text-gray-900">
                                                        Actividad: {parametros.titulo}
                                                    </h2>
                                                    <h2 className="mt-6 text-center  tracking-tight text-gray-900">
                                                        Modalidad:{parametros.modalidad}
                                                    </h2>
                                                    <h4 className='text-center font-bold'>Fecha: {parametros.inicio}</h4>
                                                </div>
                                                <form id="formulario" className="mt-8 space-y-6" action="https://api.universitea.cl/1" method="POST">
                                                <input type="hidden" value="1234" name="password"/>
                                                    <div className="-space-y-px rounded-md shadow-sm">
                                                        <div>
                                                            <label htmlFor="name" className="sr-only">
                                                                Nombre
                                                            </label>
                                                            <input
                                                                onChange={handleChanges}
                                                                id="name"
                                                                name="name"
                                                                type="string"
                                                                required={true}
                                                                className="values relative block w-full appearance-none rounded-none rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="Tu nombre"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="mobile" className="sr-only">
                                                                Teléfono
                                                            </label>
                                                            <input
                                                                onChange={handleChanges}
                                                                id="mobile"
                                                                name="mobile"
                                                                type="number"
                                                                required={true}
                                                                className="values relative block w-full appearance-none rounded-none rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="Tu celular"
                                                            />
                                                        </div>
                                                        <div>
                                                            <label htmlFor="email" className="sr-only">
                                                                Correo Electrónico
                                                            </label>
                                                            <input
                                                                onChange={handleChanges}
                                                                id="email"
                                                                name="email"
                                                                type="email"
                                                                required={true}
                                                                className="values relative block w-full appearance-none rounded-none rounded-t-md border border-grey500 px-3 py-2 text-gray-900 placeholder-gray-500 focus:z-10 focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
                                                                placeholder="Tu correo personal"
                                                            />
                                                        </div>
                                                        <div>
                                                            <input
                                                                onChange={handleChanges}
                                                                id="cuidador"
                                                                name="cuidador"
                                                                type="checkbox"
                                                                required={true}
                                                                className=""
                                                            />
                                                            <label htmlFor="cuidador" className=" text-xs text-center">
                                                                {' Soy Cuidador/Familiar de niño/a con Autismo'}
                                                            </label>
                                                        </div>
                                                        <div className=' inline-block align-middle'>
                                                            <input
                                                                onChange={handleChanges}
                                                                id="profesional"
                                                                name="profesional"
                                                                type="checkbox"
                                                                required={true}
                                                                className=""
                                                            />
                                                            <label htmlFor="profesional" className=" text-xs text-center">
                                                                {' Soy Profesional de Salud/Educación del área Autismo'}
                                                            </label>
                                                        </div>
                                                        
                                                    </div>
                                                    <div>
                                                        <button
                                                            type="submit"
                                                            onClick={handleSubmit}
                                                            className="group relative 
                                                            flex w-full justify-center 
                                                            rounded-md border border-transparent 
                                                            bg-blue-700 py-2 px-4 text-sm font-medium text-white 
                                                            hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                                                        >
                                                            <span className="absolute inset-y-0 left-0 flex items-center pl-3">
                                                                <LockClosedIcon className="h-5 w-5 
                                                                text-indigo-500 
                                                                group-hover:text-indigo-400" aria-hidden="true" />
                                                            </span>
                                                            Guardar
                                                        </button>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                        <div className="mt-4 flex justify-end">
                                            <button
                                                type="button"
                                                className="inline-flex bg-green-100 justify-center rounded-md border border-transparent px-4 py-2 text-sm font-medium text-blue-900 "
                                                onClick={closeModal}>
                                                Cerrar
                                            </button>
                                        </div>
                                    </Dialog.Panel>
                                </Transition.Child>
                            </div>
                        </div>
                    </Dialog>
                </Transition>
            </>
        )
    }
    if (parametros.codigo === 2 || parametros.codigo === 1) {
        return (
            <div className="text-gray-400 font-medium grid justify-items-center 
            align-middle bg-violet-200 rounded-full py-4  w-36 hover:scale-110">
                Actividad cerrada
            </div>
        )
    }
}

export default Inscribe;
