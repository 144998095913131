"use client"
import Slider from "react-slick";
import React, { Component } from "react";

interface DataType {
    nomCurso: string;
    para: string;
    name: string;
    cvDocente: string;
    docente: string;
}
const postData: DataType[] = [
    {
        nomCurso: 'Educando a los educadores',
        para: 'Educadores y Terapeutas',
        name: "Pamela Iturra Gonzalez",
        docente: './assets/docente/pame.png',
        cvDocente: 'Educadora Diferencial UMCE. Master Educación Especial e Inclusiva University College London, Docente Universidad Católica, U Alberto Hurtado, U. de las Américas, U.A. Humanismo Cristiano'
    },
    {
        nomCurso: 'Neurodesarrollo Autista',
        para: 'Educadores y Terapeutas',
        name: "Dolores Sanchez Castro",
        docente: './assets/docente/lolo.png',
        cvDocente: 'Educadora Diferencial UMCE. Directora de Centro Terapias Caminantes, Coordinadora Técnica de Escuela Especial, Certificaciones: AMAR, SILOGICE'
    },
    {
        nomCurso: 'Autismo y Contexto Escolar',
        para: 'Educadores y Cuidadores',
        name: "Constanza Lagos Carvacho",
        docente: './assets/docente/cony.png',
        cvDocente: 'Terapeuta Ocupacional USS Atención Infanto-Juvenil. '
            + 'desde el área de neuropsiquiatría clínica y en contexto educativo. '
            + 'Guía Caminantes y Tutora Caminantes en contextos escolares. '
            + 'Certificación en Autismo, CLASI y Perfeccionamientos en Autismo.'
    },
    {
        nomCurso: 'Psicología en el Autismo',
        para: 'Profesionales del área',
        name: "Pabla Álvarez Silva",
        docente: '/assets/docente/pabla.png',
        cvDocente: 'Psicóloga Clínica infanto-juvenil. Guía Caminantes. Certificación Autismo y Especialista en Autismo en Contexto Escolar.'
    },
    {
        nomCurso: 'TO Autismo',
        para: 'Profesionales TO',
        name: "Estefanía Araya Villanueva",
        docente: '/assets/docente/nia.png',
        cvDocente: 'Terapeuta Ocupacional U.MAYOR. Magister en Motricidad Infantil. Postgrado en Neuropsiquiatría Infantil. Certificación Autismo. Guía Caminantes. Docente Universitaria. Formadora de Terapeutas Ocupacionales. Especialista en Autismo en Contexto Escolar'
    },
    {
        nomCurso: 'Trabajo Curricular en Autismo',
        para: 'Educador@s y Codocentes',
        name: "Emilia Godoy Moreira",
        docente: '/assets/docente/emi.png',
        cvDocente: 'Educadora Diferencial UMCE. Guía Caminantes, Docente de Aula Escuela Caminar Libre. Certificaciones: Autismo, AMAR, SILOGICE'

    },
    {
        nomCurso: 'Silogice: Lenguaje y Transformación personal',
        para: 'Padres y Profesionales',
        name: "José Cuevas Parry",
        docente: '/assets/docente/pepe.png',
        cvDocente: 'Ingeniero Comercial USACH. Guía Caminantes, Consultor SILOGICE. Director de Capacitación Universitea'

    },
    // {
    //     nomCurso: 'Fonoaudiología en el Autismo',
    //     para: 'Fonoaudiólog@s',
    //     name: "Tamara Navarro Gonzalez",
    //     docente: '/assets/docente/tami.png',
    //     cvDocente: 'Fonoaudiologa Universidad Mayor, Guía Caminantes, Docente Universitea, Certificaciones Autismo, Prompt, Afasiología. '
    //         + 'Terapeuta de niños y niñas Autistas en establecimientos escolares y clínica privada'

    // },
]
export default class MultipleItems extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            centerMode: false,
            slidesToScroll: 1,
            arrows: true,
            autoplay: true,
            speed: 500,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                }
            ]
        };
        return (
            <div className='mx-auto max-w-7xl sm:py-4 lg:px-8 ' id="docentes">
                <h5 className=" text-midnightblue 
                text-2xl 
                text-center md:text-start font-semibold">
                    <br />
                    Guías Docentes</h5>

                <Slider {...settings}>
                    {postData.map((items, i) => (
                        <div key={i}>
                            <div className='bg-white m-3 px-3 pt-3 pb-12 my-10 shadow-courses rounded-2xl'>
                                <div className="relative rounded-3xl 
                                        transition ease-in-out 
                                        delay-150 bg-white-500 
                                        hover:bg-purple duration-300">
                                            {/* hover:-translate-y-1 hover:scale-110  */}
                                    <img src={items.docente} alt={items.name} width={180} className="m-auto clipPath" />
                                </div>
                                <div className="py-2">
                                    <div >
                                        <h3 className="text-2xl mx-auto font-bold" > {items.name}</h3>
                                    </div>
                                    <div className=" justify-between pt-6 inline-block m-auto">
                                        <div className="flex gap-4 align-middle">
                                            <h1 className="text-sm font-medium text-black opacity-75 align-middle">
                                                {items.cvDocente}
                                            </h1>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </Slider>
            </div>
        );
    }
}
