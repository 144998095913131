"use client"
import Slider from "react-slick";
import React, { Component } from "react";
import Inscribe from "./Inscribe.tsx";

interface DataType {
    codigo:number;
    bajada: string;
    titulo: string;
    docente: string;
    descripcion: string;
    duracion: string;
    modalidad:string;
    semanas: number;
    contenidos: string;
    objetivo: string;
    inicio: string;
    fin: string;
    imgSrc: string;
    dirigido: string;
    metodologia: string;
    certificados: string;
    vigente: boolean;
}

const postData: DataType[] = [
    {
        codigo:4,
        bajada: "Adquisición de técnicas para facilitar la Organización Emocional y Conductual de Personas Autistas ",
        titulo: "Transformación Profesional, para facilitar la Organización Emocional y Conductual de Personas Autistas",
        docente: 'Emilia Godoy,Dolores Sánchez, José Cuevas ',
        duracion: "4",
        modalidad:'Curso práctico. Técnicas Profesionales en base a casos reales de escuela : Emilia Godoy. Método OJEA: Dolores Sanchez. SILOGICE, Transformación a través del lenguaje : José Cuevas',
        semanas: 0,
        inicio: '02-04-2024 y 09-04-2024',
        fin: '',
        dirigido: 'Profesionales de la Educación Diferencial',
        descripcion: 'Dos Talleres',
        objetivo: 'Al finalizar la Ruta de Aprendizaje el/la Docente será capaz de: a) Trabajar con niñ@s autistas en aula escolar b) Crear las adecuaciones requeridas para su grupo de escolares autistas c) Comprender y aplicar con efectividad las técnicas de regulación emocional en Aula Autista ',
        contenidos: 'a) Planificación Curricular para el autismo, b) El lenguaje como generador de Efectividad Docente, c) Análisis y Adecuación del Currículum para el Aula Autista',
        metodologia: '100% práctica y de mentoría personal, en base a la teorías Educacionales Aplicables y Currículum Establecido. a) 48 horas de Experiencia en Aula Caminantes b) 8 Horas de mentoría personal con las Guías Docentes Caminantes',
        certificados: 'Certificado de Participación.',
        imgSrc: '/assets/Certificaciones/OJEA.png',
        vigente:true,
    },
    {
        codigo:3,
        bajada: "Certificación en Competencias para la Organización Emocional y Conductual de Personas Autistas ",
        titulo: "Programa Transformación Profesional para la Organización Emocional y Conductual de Personas Autistas",
        docente: 'Emilia Godoy, José Cuevas, Dolores Sánchez, Pabla Álvarez, Estefanía Araya',
        duracion: "8 semanas, 64",
        modalidad:'Talleres de capacitación grupal. Observación en aula y retroalimentación al docente. Mentoría Personalizada',
        semanas: 8,
        inicio: 'Programa Permanente',
        fin: '',
        dirigido: 'Profesionales de la Educación',
        descripcion: '56 horas de práctica: 6 horas cada semana en Aula Caminantes y 2 horas semanales de observación de la Guía Docente en el Aula de la Institución de Educación, durante 8 semanas.',
        objetivo: 'Al finalizar la Ruta de Aprendizaje el/la Docente será capaz de: a) Trabajar con niñ@s autistas en aula escolar b) Crear las adecuaciones requeridas para su grupo de escolares autistas c) Comprender y aplicar con efectividad las técnicas de regulación emocional en Aula Autista ',
        contenidos: 'a) Planificación Curricular para el autismo, b) El lenguaje como generador de Efectividad Docente, c) Análisis y Adecuación del Currículum para el Aula Autista',
        metodologia: '100% práctica y de mentoría personal, en base a la teorías Educacionales Aplicables y Currículum Establecido. a) 48 horas de Experiencia en Aula Caminantes b) 8 Horas de mentoría personal con las Guías Docentes Caminantes',
        certificados: 'Certificado de Competencia en Aula Autismo y Certificado de Experiencia Práctica en Aula Autismo.',
        imgSrc: '/assets/Certificaciones/codocente.png',
        vigente:true,
    },

    {
        codigo:1,
        bajada: "Decidiendo un modelo Escolar para mi hijo o hija autista",
        titulo: "Autismo y Necesidades Especiales. Sistema escolar regular en Chile",
        docente: 'Dolores Sanchez, Emilia Godoy, Constanza Lagos, Tamara Navarro, Estefanía Araya, Pabla Álvarez',
        duracion: '2',
        modalidad:'Online',
        semanas: 0,
        inicio: '18/01/2024 19:30 horas ',
        fin: '',
        dirigido: 'Padres y Cuidadores',
        descripcion: 'Taller conversatorio y testimonial para padres. Dirigido por Padres y Profesionales',
        objetivo: 'Orientaciones para el proceso de escolarización de niños y niñas con autismo',
        contenidos: 'Objetivos de la escolarización regular. El autismo en el aula regular. Escenario 2024 en el sistema escolar chileno',
        metodologia: 'Exposición y foro de respuesta a consultas',
        certificados: 'Certificado de Participación',
        imgSrc: '/assets/Certificaciones/aula.png',
        vigente:false,
    },
    {
        codigo:2,
        bajada: "Estrategias Aplicadas a TO de Niños y Niñas con Autismo",
        titulo: "Terapia Ocupacional Grupal a niños y niñas Autistas",
        docente: 'Estefanía Araya, Dolores Sanchez',
        duracion: '24',
        modalidad:'Práctica en Centro Caminantes y Mentoría Personal',
        semanas: 8,
        inicio: 'Inicio 07/12/2023',
        fin: 'Término 25/01/2024',
        dirigido: 'Terapeutas Ocupacionales',
        descripcion: '24 horas de práctica, 3 horas cada semana, durante 8 semanas con grupo de niñas y niños autistas',
        objetivo: 'Al finalizar la Ruta de Aprendizaje el/la Profesional será capaz de: a) Trabajar con niñ@s autistas en terapia grupal b) Planificar una sesión grupal para grupo de niñ@s autistas c) Realizar retroalimentación y redactar informes de terapia coherentes al objetivo y a lospropósitos terapéuticos integrales del niño, escuela, familia/cuidadores',
        contenidos: 'Planificación Terapéutica para el autismo. El lenguaje como generador de acción terapéutica. Incorporación del entorno extra-sesión en la praxis terapéutica',
        metodologia: '100% práctica y de mentoría personal, en base a la teoría de Terapia Ocupacional. a) 8 horas de Experiencia en espacio de trabajo con niños y niñas en taller de TerapiaOcupacional b) Mentoría personal con la Docente antes y después de cada Experiencia',
        certificados: 'Certificado de Competencia en Atención grupal Autismo y Certificado de Experiencia Práctica en Atención grupal Autismo',
        imgSrc: '/assets/Certificaciones/grupal.png',
        vigente:false,
    },



]



export default class MultipleItems extends Component {
    render() {
        const settings = {
            dots: false,
            infinite: true,
            slidesToShow: 3,
            // centerMode: true,
            slidesToScroll: 2,
            arrows: true,
            autoplay: false,
            speed: 500,
            cssEase: "linear",
            responsive: [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                },
                {
                    breakpoint: 600,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                        infinite: true,
                        dots: false
                    }
                }
            ]
        };
        return (
            <div id="actividades">
                <div className='mx-auto max-w-7xl pt-4 px-4 lg:px-8 '>
                    <div className=" items-center">
                        <h6 className=" text-midnightblue text-2xl 
                            lg:text-3xl font-semibold mb-1 text-center">
                            Actividades y Certificaciones
                        </h6>
                    </div>
                    <Slider {...settings}>
                        {postData.map((items, i) => (
                            <div key={i} className="" id={items.codigo.toString()}>
                                
                                <div className='bg-white m-3 px-3 pt-2 pb-12 my-10 shadow-courses rounded-2xl'>
                                    {/* Imagen */}
                                    <div className="rounded-2xl mx-auto pt-2 pb-3">
                                        <img src={items.imgSrc}
                                            alt={items.titulo}
                                            className="   px-1  rounded-2xl w-full" />
                                    </div>
                                    {/* GLobo con el título o titulo */}
                                    <div className=" bg-blue-200 rounded-2xl ">
                                        <p className=' inline-block text-base font-bold text-center lg:text-xl 
                                                lg:font-bold 
                                                text-violet-700 px-3 pt-3 pb-3'>
                                            {items.titulo}
                                        </p>
                                    </div>
                                    {/* Bajada o Detalle */}
                                    <p className='text-center  
                                        pt-2 px-2 pb-2  text-violet-700 opacity-75'>
                                        {items.bajada}
                                    </p>
                                    <hr className="pb-4" />

                                    <div className="inline-flex align-top pb-6">
                                        <img src={'/assets/actividades/docente.svg'}
                                            alt="docente" width={40}
                                            className=" align-top" />
                                        <div className="text-violet-700 px-2">
                                            <strong className=" ">{"Guías: "}</strong>
                                            <p>{items.docente}</p>
                                        </div>
                                    </div>
                                    <div className="inline-flex align-top pb-6">
                                        <img src={'/assets/actividades/certificado.svg'}
                                            alt="certificado" width={40}
                                            className=" align-top" />
                                        <div className="text-violet-700 px-2">
                                            <strong className=" ">{"Certificados: "}</strong>
                                            <p>{items.certificados}</p>
                                        </div>
                                    </div>
                                    <div className="flex align-top pb-6">
                                        <img src={'/assets/actividades/clock.svg'}
                                            alt="certificado" width={40}
                                            className=" align-top" />
                                        <div className="text-violet-700 px-1  
                                             text-1xl ">
                                            <strong className=" ">{"Extensión y Modalidad: "}</strong>
                                            <p>{items.duracion} {'horas.'} {items.modalidad}</p>
                                        </div>
                                    </div>
                                    <div className="flex align-top pb-6">
                                        <img src={'/assets/actividades/calendario.svg'}
                                            alt="calendario" width={40}
                                            className=" align-top" />
                                        <div className="text-violet-700 px-1  
                                            text-justify text-1xl ">
                                            <strong className=" ">
                                                {"Fechas : "}</strong>
                                                <p>{items.inicio} {items.fin}</p>
                                        </div>
                                    </div>
                                    <div className="flex align-top">
                                        <img src={'/assets/actividades/apply.svg'}
                                            alt="calendario" width={40}
                                            className=" align-top" />
                                            <div className="p-8 justify-items-center">
                                                <Inscribe parametros={items} nomActividad={items.titulo}/>
                                            </div>
                                    </div>
                                </div>
                            </div>
                        ))}
                    </Slider>
                </div>
            </div>
        );
    }
}


