
import React from 'react';
import {Routes, Route, Link} from 'react-router-dom';
import EditUser from './EditUser';
import ListUser from './ListUser';
import Registerdialog from '../Navbar/Registerdialog.tsx'

function AdminUser() {
  return (
    <div className=" group-autofill:sr-only sticky">
            <nav>
          <ul>
            <li>
              <Link to="/AdminUser">Lista de Estudiantes</Link>
            </li>
            <li>
              <Link to="user/create">Registrar Nuevo Estudiante</Link>
            </li>
          </ul>
        </nav>
      <Routes>
        <Route index element={<ListUser />} />
        <Route path="/*" element=""/>
        <Route path="user/create" element={<Registerdialog />} />
        <Route path="/:id/edit" element={<EditUser />} />
      </Routes>
    </div>
  );
}

export default AdminUser;

