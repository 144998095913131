import axios from "axios"
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";

export default function ListUser() {
    var [users, setUsers] = useState([]);
    useEffect(() => {
        getUsers();
    }, []);
    function getUsers() {
        axios.get('https://api.universitea.cl/users').then(function (response) {
            setUsers(response.data);
        });
    }
    const deleteUser = (id, nomUser) => {
            axios.delete(`https://api.universitea.cl/users/${id}/delete`).then(function (response) {
                getUsers();
            });
    }
    return (
        <div>
            <h3>ESTUDIANTES REGISTRADOS</h3>
            <table>
                <thead>
                    <tr>
                        <th>#</th>
                        <th>Fecha</th>
                        <th>Nombre</th>
                        <th>Email</th>
                        <th>Celular</th>
                        <th>Cuidador</th>
                        <th>Profesional</th>
                        <th>UniPass</th>
                        <th>Acción</th>
                    </tr>
                </thead>
                <tbody>
                    {users.map((user, key) =>
                        <tr key={key}>
                            <td>{user.id}</td>
                            <td>{user.created_at.substr(0,10)}</td>
                            <td>{user.name}</td>
                            <td>{user.email}</td>
                            <td>{user.mobile}</td>
                            <td>{user.cuidador}</td>
                            <td>{user.profesional}</td>
                            <td>{user.password}</td>
                            <td>
                                <Link to={`${user.id}/edit`} style={{ marginRight: "10px" }} className=" rounded">Edit</Link>
                                <button className=" rounded" onClick={() => deleteUser(user.id, user.name)}>Delete</button>
                            </td>
                        </tr>
                    )}

                </tbody>
            </table>
        </div>
    )
}
