import * as React from 'react';
import { ZegoUIKitPrebuilt } from '@zegocloud/zego-uikit-prebuilt';
function randomID(len: number) {
  let result = '';
  if (result) return result;
  var chars = '12345qwertyuiopasdfgh67890jklmnbvcxzMNBVCZXASDQWERTYHGFUIOLKJP',
    maxPos = chars.length,
    i;
  len = len || 5;
  for (i = 0; i < len; i++) {
    result += chars.charAt(Math.floor(Math.random() * maxPos));
  }
  return result;
}
export function getUrlParams(
  url = window.location.href
) {
  let urlStr = url.split('?')[1];
  return new URLSearchParams(urlStr);
}
export default function Conferencia() {
  // const roomID = getUrlParams().get('roomID') || randomID(5);
  const roomID = "Conversatorio-1"
  let myMeeting = async (element: any) => {
    // generate Kit Token
    const appID = 708102343;
    const serverSecret = "7fc9cf5b2e2d110978a1c06119d47945";
    //  const kitToken =  ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID,  randomID(5),  randomID(5));
    const kitToken = ZegoUIKitPrebuilt.generateKitTokenForTest(appID, serverSecret, roomID, "Conversatorio", "Dolores");

    // Create instance object from Kit Token.

    // start the call
    var rol: string ;
    var configAdmin = {}
    rol = 'admin';
    var compartir:any = []
    if (rol === 'admin') {
        configAdmin = {
        container: element,
        turnOnMicrophoneWhenJoining: true,
        turnOnCameraWhenJoining: true,
        showMyCameraToggleButton: true,
        showMyMicrophoneToggleButton: true,
        showAudioVideoSettingsButton: true,
        showScreenSharingButton: true,
        showTextChat: true,
        showUserList: true,
        maxUsers: 50,
        layout: "Auto",
        showLayoutButton: true,
        sharedLinks: [
        {
          name: 'Copiar Link de esta reunión',
          url:
            window.location.protocol + '//' +
            window.location.host + window.location.pathname +
            '?roomID=' +
            roomID,
        },
      ],
        scenario: {
           mode: ZegoUIKitPrebuilt.VideoConference,
        },
      }
    }

    const zp = ZegoUIKitPrebuilt.create(kitToken);
    zp.joinRoom(configAdmin);
  };
  return (
    <div
      className="flex"
      ref={myMeeting}
      style={{ width: '100vw', height: '100vh' }}
    ></div>
  );
}