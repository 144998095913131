import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter, Routes, Route} from 'react-router-dom';
import './globals.css';
import App from './App.js';
import AdminUser from './components/AdminUser/index.tsx'
import Certificado from './components/Certificado/index.tsx'
import Conferencia from './components/Conferencia/index.tsx'


const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <React.StrictMode>
     <BrowserRouter>
      <Routes>
        <Route path="/" element={<App />} />
        <Route index element={<App />} />
        <Route path='AdminUser/*' element={<AdminUser/>}/>
        <Route path='Certificado' element={<Certificado/>}/>
        <Route path='Conferencia' element={<Conferencia/>}/>
        <Route path="*" element={<App />} />
      </Routes>
    </BrowserRouter>
  </React.StrictMode>

);
