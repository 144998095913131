import React from "react";

const footer = () => {
    return (
        <div className=" text-xs mx-auto max-w-2xl sm:pt-24 px-4 sm:px-6 lg:max-w-7xl lg:px-8">
            <p className=" text-xs">@ Hecho en 2023 por José Cuevas Parry + www.masvale.cl</p>
        </div>
    )
}

export default footer;
