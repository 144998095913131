import React from 'react';
import Navbar from './components/Navbar/Navbar.tsx';
import Footer from './components/Footer/Footer.tsx';
import Banner from './components/Banner/index.tsx';
import Actividades from './components/Actividades/index.tsx'
import Docentes from './components/Docentes/index.tsx';
import Certificado from './components/Certificado/index.tsx'
import AdminUser from './components/AdminUser/index.tsx';

export default function App() {
  return (
    <main className="bg-purple-200/100">
      <Navbar />
      <Banner />       
      {/* <AdminUser/> */}
      <Actividades/>
      <Docentes />
      {/* <Certificado /> */}
      <Footer/>
    </main>
  )
}

