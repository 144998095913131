import React from "react";
import Signdialog from "./Signdialog.tsx";
import Registerdialog from "./Registerdialog.tsx";

interface NavigationItem {
  name: string;
  href: string;
  current: boolean;
}

const navigation: NavigationItem[] = [

  { name: 'Inicio', href: '/', current: true },
  { name: 'Actividades y Certificaciones', href: '#actividades', current: false },
  { name: 'Docentes', href: '#docentes', current: false },
  // { name: 'Videollamada',href: 'conferencia', current: false },
  // { name: 'Usuarios', href: 'AdminUser', current: false },
  // { name: 'Diplomas', href: 'certificado', current: false },

];

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(' ')
}

const Data = () => {
  return (
    <div className="rounded-md max-w-sm w-full mx-auto">
      <div className="flex-1 space-y-4 py-1">
        <div className="sm:block">
          <div className="space-y-1 px-5 pt-2 pb-3">
            {navigation.map((item) => (
              <a
                key={item.name}
                href={item.href}
                className={classNames(
                  item.current ? 'text-black hover:opacity-100' : 'hover:text-black hover:opacity-100',
                  'py-1 text-lg font-normal opacity-75 block'
                )}
                aria-current={item.current ? 'page' : undefined}
              >
                {item.name}
              </a>
            ))}
            <div className="mt-4">
              {/* <button>
                <Signdialog />
              </button> */}
            </div>
            <div className="mt-4">
              {/* <button>
                <Registerdialog />
              </button> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Data;
