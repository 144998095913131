import React from 'react';
import { Text, View, StyleSheet, Image } from '@react-pdf/renderer';
import bottomleft from './bottomleft.png'

const styles = StyleSheet.create({

    titleContainer: {
        marginTop: 18,
        alignItems: 'center',
    },

});


const CertPieFirma = () => (
        <View style={styles.titleContainer}>
            <Image src={bottomleft} />
        </View>

);

export default CertPieFirma